.kb-loading {
  position: absolute !important;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 9999 !important;
}
.kb-loading-table {
  position: absolute !important;
  top: 25%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-25%, -50%);
  z-index: 9999 !important;
}
.kb-firstCapital {
  text-transform: capitalize !important;
  cursor: pointer !important;
}
.kb-overlay {
  opacity: 0.3;
}
.kb-topmenu {
  background-color: #fff !important;
}
.kb-move-icon svg {
  width: 10px !important;
}
.kb-table-draggable {
  width: 100% !important;
}
.cursor-pointer {
  cursor: pointer;
}

legend {
  float: none !important;
}

.MuiInputLabel-outlined {
  z-index: 1 !important;
}

.bg-primary {
  background-color: rgb(0 123 255 / 1) !important;
}

.send-btn {
  font-family: inherit;
  font-size: 16px;
  background: royalblue;
  color: white;
  padding: 0.4em 1.5em;
  padding-left: 1.5em;
  display: flex;
  gap: 5px;
  align-items: center;
  border: none;
  border-radius: 16px;
  overflow: hidden;
  transition: all 0.2s;
  cursor: pointer;
}

.send-btn span {
  display: block;
  margin-left: 0.3em;
  transition: all 0.3s ease-in-out;
}

.send-btn svg {
  display: block;
  transform-origin: center center;
  transition: transform 0.3s ease-in-out;
  transform: rotate(0deg) scale(1.3);
}

.send-btn:hover .svg-wrapper {
  animation: fly-1 0.6s ease-in-out infinite alternate;
}

.send-btn:hover svg {
  transform: translateX(1.6em) rotate(45deg) scale(1.3);
}

.send-btn:hover span {
  transform: translateX(5em);
}

.send-btn:active {
  transform: scale(0.95);
}

@keyframes fly-1 {
  from {
    transform: translateY(0.1em);
  }

  to {
    transform: translateY(-0.1em);
  }
}

.loader-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  z-index: 1;
}

.order-table-container {
  height: calc(100vh - 190px);
}

.close-btn {
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  padding: 0.375rem;
  height: 2rem !important;
  width: 2rem !important;
  background-color: white;
  border-radius: 50%;
  color: #4b5563;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
  border: 1px solid #e5e7eb;
  z-index: 9;
}

.navbar-vertical .navbar-collapse:before {
  display: none;
}

.profile-pic-wrapper {
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  border: 1.5px solid lightgray;
  box-shadow: 0 0 5px 0 lightgray;
}

.overlay {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.profile-pic-wrapper:hover .overlay {
  display: flex;
}

@keyframes splash-animation {
  0% {
    opacity: 0.4;
    transition: all 0.8s;
    background-color: rgba(0, 140, 255, 0.772);
  }
  50% {
    opacity: 0.6;
    background-color: rgba(0, 140, 255, 0.772);
    transition: all 0.8s;
  }
  80% {
    opacity: 0.8;
    background-color: rgba(0, 140, 255, 0.564);
    transition: all 0.8s;
  }
  100% {
    opacity: 1;
    transition: all 0.8s;
  }
}

.splash {
  animation: splash-animation 5s ease-out normal;
  transition: all 0.8s;
}

.user-profile {
  border-radius: 100%;
  border: 1.5px solid lightgray;
  box-shadow: 0 0 5px 0 lightgray;
}

.border-start {
  border-left: 1px solid lightgray;
}

.border-end {
  border-right: 1px solid lightgray;
}

.underline {
  text-decoration: underline;
}

textarea {
  field-sizing: content;
  min-height: 100px;
}
